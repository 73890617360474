@import './nested-color.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  a,
  strong,
  label,
  div,
  li {
    @apply text-dark dark:text-white;
  }

  h1 {
    @apply h1;
  }

  h2 {
    @apply h2;
  }

  h3 {
    @apply h3;
  }

  p {
    @apply p;
  }

  span {
    @apply span;
  }

  strong {
    @apply strong;
  }

  article h1 {
    @apply text-3xl font-bold sm:text-4xl;
  }

  article h2 {
    @apply text-3xl font-semibold sm:text-3xl;
  }
}

@layer components {
  .h1 {
    @apply text-4xl  font-bold  sm:text-5xl lg:text-6xl;
  }

  .h2 {
    @apply text-3xl font-semibold  sm:text-4xl xl:font-bold;
  }

  .h3 {
    @apply text-xl font-semibold sm:text-2xl lg:text-2xl;
  }

  .h4 {
    @apply text-lg font-semibold sm:text-xl lg:text-xl;
  }

  .p {
    @apply text-sm sm:text-base md:text-base lg:text-lg xl:text-xl;
  }

  .span {
    @apply font-extralight;
  }

  .strong {
    @apply font-bold;
  }

  .container {
    @apply mx-auto max-w-[1280px] px-2.5 sm:px-4 md:px-8;
  }

  .container-p {
    @apply px-2.5 sm:px-4 md:px-8;
  }

  .container-p-0 {
    @apply px-0;
  }

  .gradient-primary {
    @apply bg-gradient-to-r from-primary-500 to-dark;
  }

  .border-full {
    @apply border-stone-100 dark:border-stone-700;
  }

  /* Swiper */
  .main-wrap .swiper .swiper-scrollbar {
    @apply left-0 right-0 mx-auto !block h-[5px] w-[calc(100%_-_20px)] bg-dark/20 dark:bg-white/20 sm:w-[calc(100%_-_32px)] md:w-[calc(100%_-_64px)] lg:w-[calc(100%)];
  }

  .dark-none .main-wrap .swiper .swiper-scrollbar {
    @apply bg-white/20;
  }

  .main-wrap .swiper .swiper-scrollbar .swiper-scrollbar-drag {
    @apply cursor-pointer bg-dark/80 dark:bg-white/80;
  }

  .dark-none .main-wrap .swiper .swiper-scrollbar .swiper-scrollbar-drag {
    @apply bg-white/80;
  }

  /* end swiper */

  .paginate ul li a {
    @apply font-open text-inherit;
  }

  .article-small {
    @apply container !max-w-4xl;
  }

  /* content html */
  .content p {
    @apply font-open text-sm sm:text-base;
  }

  .content figure {
    @apply relative mb-4;
  }

  .content ul {
    @apply grid list-inside list-disc grid-cols-1 gap-2 md:grid-cols-2;
  }
  .content ul li {
    @apply w-fit text-sm !font-normal sm:text-base lg:text-base;
  }

  .content ul li::marker {
    @apply !block h-8 w-8 text-primary-500;
  }

  .content img {
    @apply rounded-xl;
  }
  .content img::after {
    @apply absolute inset-0 z-20 bg-gradient-to-t from-dark to-transparent content-['_'];
  }

  .content figcaption {
    @apply text-drop-shadow absolute bottom-1 left-2 rounded-md px-1 text-xs text-white contrast-200 lg:px-2 lg:text-sm;
  }

  .content figcaption::before {
    @apply -bottom-2 left-0 mr-1 h-8 w-full content-['\00a9'];
  }

  /* content html itinerary card */
  .px-itinerary-card {
    @apply mx-auto px-2 md:px-4;
  }
  .swiper-slide-itineray-card {
    @apply ml-4 first:ml-2 last:pr-8 md:first:ml-4;
  }

  .content-itinerary-card {
    @apply text-gray-700;

    & figure {
      @apply relative;
    }

    & p {
      @apply mb-4 text-sm sm:text-sm;
    }
  }

  .content-itinerary-card img {
    @apply mb-4 rounded-xl;
  }
  .content-itinerary-card img::after {
    @apply absolute inset-0 z-20 bg-gradient-to-t from-dark to-transparent content-['_'];
  }

  .content-itinerary-card figcaption {
    @apply absolute bottom-1 left-2 text-sm text-white mix-blend-overlay contrast-200;
  }

  /* utility dark mode */
  .shadow-full {
    @apply shadow-full-dark dark:shadow-full-light;
  }

  .shadow-card {
    @apply shadow-card-dark dark:shadow-card-light;
  }

  .bg-gray-light-dark {
    @apply bg-gray-light dark:bg-gray-dark;
  }

  /* inverted corner */
  .inverted-corner-after::after {
    @apply absolute -top-8 right-0 h-8 w-5 rounded-br-full bg-transparent mix-blend-color shadow-[0_17px_0_0_] shadow-primary-500 content-[''];
  }

  .inverted-corner-after::before {
    @apply absolute -left-[19px] bottom-0 h-10 w-5 rounded-br-full bg-transparent shadow-[0_18px_0_0_] shadow-primary-500 content-[''];
  }

  /* itinerary map */
  .custom-popup {
    & .mapboxgl-popup-content {
      @apply mb-4 w-52 rounded-xl bg-white p-2 shadow-card-dark dark:bg-gray-dark dark:shadow-card-light lg:rounded-2xl lg:p-3;

      & h3 {
        @apply mb-2 text-base font-bold;
      }
    }
    & .mapboxgl-popup-tip {
      @apply hidden;
    }
  }
  .custom-popup-none {
    & .mapboxgl-popup-content {
      @apply hidden;
    }
  }
}

@layer utilities {
  .link-underline {
    border-bottom-width: 0;
    background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
    background-size: 0 1px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size 0.5s ease-in-out;
  }

  .link-underline-primary {
    background-image: linear-gradient(transparent, transparent), linear-gradient(#a91314, #a91314);
  }

  .link-underline-dark {
    background-image: linear-gradient(transparent, transparent), linear-gradient(#252525, #252525);
  }

  .link-underline:hover {
    background-size: 100% 1px;
    background-position: 0 100%;
  }

  .text-drop-shadow {
    filter: drop-shadow(0px 0px 5px #000);
  }

  .swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
  }

  /* wrapper height animation */
  /* TODO: verificare il collapse -> con display grid swiper non funziona (itinerary card) */
  /* #wrapper-height {
    display: grid;
    grid-template-rows: 0fr;
    grid-column: span 1;
    transition: 0.7s ease-out;
    overflow: hidden;
  }
  #wrapper-height .inside {
    min-height: 60px;
  }
  #wrapper-height.expanded {
    grid-template-rows: 1fr !important;
  } */

  /* react-nice-dates */

  .wrapper-nice-date .nice-dates-day {
    color: #a91314 !important;
  }

  .wrapper-nice-date .nice-dates-navigation {
    color: #a91314 !important;
  }

  .wrapper-nice-date .nice-dates-day:after {
    border: 2px solid #a91314 !important;
  }

  .wrapper-nice-date .nice-dates-day:before {
    background-color: #a91314;
  }
}
