@tailwind components;

@layer components {
  :root {
    --color-primary-50: 252 227 227; /* #FCE3E3 */
    --color-primary-100: 248 195 195; /* #F8C3C3 */
    --color-primary-200: 242 140 140; /* #F28C8C */
    --color-primary-300: 235 81 81; /* #EB5151 */
    --color-primary-400: 229 26 26; /* #E51A1A */
    --color-primary-500: 169 19 20; /* #A91314 */
    --color-primary-600: 138 15 15; /* #8A0F0F */
    --color-primary-700: 101 11 11; /* #650B0B */
    --color-primary-800: 69 8 8; /* #450808 */
    --color-primary-900: 32 4 4; /* #200404 */
  }

  .filiera {
    --color-primary-50: 252 227 227; /* #FCE3E3 */
    --color-primary-100: 248 195 195; /* #F8C3C3 */
    --color-primary-200: 242 140 140; /* #F28C8C */
    --color-primary-300: 235 81 81; /* #EB5151 */
    --color-primary-400: 229 26 26; /* #E51A1A */
    --color-primary-500: 169 19 20; /* #A91314 */
    --color-primary-600: 138 15 15; /* #8A0F0F */
    --color-primary-700: 101 11 11; /* #650B0B */
    --color-primary-800: 69 8 8; /* #450808 */
    --color-primary-900: 32 4 4; /* #200404 */
  }

  .agriturismi {
    --color-primary-50: 217 247 233; /*      rgba(217,247,233,1.0) */
    --color-primary-100: 179 239 211; /*     rgba(179,239,211,1.0) */
    --color-primary-200: 107 225 170; /*     rgba(107,225,170,1.0) */
    --color-primary-300: 41 199 125; /*      rgba(41,199,125,1.0) */
    --color-primary-400: 25 123 77; /*       rgba(25,123,77,1.0) */
    --color-primary-500: 10 49 31; /*        rgba(10,49,31,1.0) */
    --color-primary-600: 8 38 24; /*         rgba(8,38,24,1.0) */
    --color-primary-700: 6 30 19; /*         rgba(6,30,19,1.0) */
    --color-primary-800: 4 21 13; /*         rgba(4,21,13,1.0) */
    --color-primary-900: 2 8 5; /*           rgba(2,8,5,1.0) */
    --color-primary-950: 1 4 3; /*           rgba(1,4,3,1.0) */
  }

  .frantoi {
    --color-primary-50: 239 242 238; /* #EFF2EE */
    --color-primary-100: 224 228 220; /* #E0E4DC */
    --color-primary-200: 190 200 183; /* #BEC8B7 */
    --color-primary-300: 159 173 148; /* #9FAD94 */
    --color-primary-400: 125 144 111; /* #7D906F */
    --color-primary-500: 95 109 84; /* #5F6D54 */
    --color-primary-600: 75 86 67; /* #4B5643 */
    --color-primary-700: 58 66 51; /* #3A4233 */
    --color-primary-800: 38 43 33; /* #262B21 */
    --color-primary-900: 20 23 18; /* #141712 */
    --color-primary-950: 10 12 9; /* #0A0C09 */
  }

  .cantine {
    --color-primary-50: 247 238 238; /* #F7EEEE */
    --color-primary-100: 237 217 218; /* #EDD9DA */
    --color-primary-200: 221 182 185; /* #DDB6B9 */
    --color-primary-300: 203 144 148; /* #CB9094 */
    --color-primary-400: 185 106 111; /* #B96A6F */
    --color-primary-500: 161 76 82; /* #A14C52 */
    --color-primary-600: 128 60 65; /* #803C41 */
    --color-primary-700: 97 46 49; /* #612E31 */
    --color-primary-800: 66 31 33; /* #421F21 */
    --color-primary-900: 31 15 16; /* #1F0F10 */
    --color-primary-950: 17 8 9; /* #110809 */
  }

  .ristoranti {
    --color-primary-50: 248 245 238; /*#f8f5ee */
    --color-primary-100: 237 231 212; /* #ede7d4 */
    --color-primary-200: 220 206 172; /* #dcceac */
    --color-primary-300: 199 175 125; /* #c7af7d */
    --color-primary-400: 182 149 89; /* #b69559 */
    --color-primary-500: 131 95 57; /* #835f39 */
    --color-primary-600: 116 80 52; /* #745034 */
    --color-primary-700: 116 80 52; /* #745034 */
    --color-primary-800: 98 67 49; /* #624331 */
    --color-primary-900: 85 58 46; /* #553a2e */
    --color-primary-950: 48 30 24; /* #301e18 */
  }

  .nature {
    --color-primary-50: 239 245 239; /* #EFF5EF */
    --color-primary-100: 224 234 224; /* #E0EAE0 */
    --color-primary-200: 190 214 190; /* #BED6BE */
    --color-primary-300: 159 194 159; /* #9FC29F */
    --color-primary-400: 125 174 125; /* #7DAE7D */
    --color-primary-500: 95 154 95; /* #5F9A5F */
    --color-primary-600: 75 123 75; /* #4B7B4B */
    --color-primary-700: 58 93 58; /* #3A5D3A */
    --color-primary-800: 38 62 38; /* #263E26 */
    --color-primary-900: 20 31 20; /* #141F14 */
    --color-primary-950: 10 16 10; /* #0A100A */
  }

  .sky-blue {
    --color-primary-50: 239 245 245; /* #EFF5F5 */
    --color-primary-100: 224 234 234; /* #E0EAEA */
    --color-primary-200: 190 214 214; /* #BED6D6 */
    --color-primary-300: 159 194 194; /* #9FC2C2 */
    --color-primary-400: 125 174 174; /* #7DAEAE */
    --color-primary-500: 95 154 154; /* #5F9A9A */
    --color-primary-600: 75 123 123; /* #4B7B7B */
    --color-primary-700: 58 93 93; /* #3A5D5D */
    --color-primary-800: 38 62 62; /* #263E3E */
    --color-primary-900: 20 31 31; /* #141F1F */
    --color-primary-950: 10 16 16; /* #0A1010 */
  }

  .place {
    --color-primary-50: 236 239 243; /* #ECEFF3 */
    --color-primary-100: 220 226 234; /* #DCE2EA */
    --color-primary-200: 185 197 212; /* #B9C5D4 */
    --color-primary-300: 151 168 191; /* #97A8BF */
    --color-primary-400: 113 137 168; /* #7189A8 */
    --color-primary-500: 86 109 140; /* #566D8C */
    --color-primary-600: 68 86 111; /* #44566F */
    --color-primary-700: 52 67 85; /* #344355 */
    --color-primary-800: 35 44 57; /* #232C39 */
    --color-primary-900: 17 22 28; /* #11161C */
    --color-primary-950: 8 10 13; /* #080A0D */
  }
}
